import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { ApiSetupType } from '../../types/api'


export const setDocumentTitle = (lang: ApiSetupType["lang"]) => {
  const url = window.location.hostname.split(".")
  const currentDomain = url.length === 3 ? url[1] : url[0]
  const favicon = document.getElementById("favicon") as HTMLLinkElement

  switch (currentDomain) {
    case "meetor":
      document.title = (lang === "ru") ? "Meetor. Онлайн-запись для бизнеса" : "Meetor. Online appointment for business"
      return favicon.href = toAbsoluteUrl("media/crm/favicon/meetor.ico")
    case "ищу-найду":
      document.title = "Ищу Найду. Мониторинг объявлений по продаже автомобилей"
      return favicon.href = toAbsoluteUrl("media/crm/favicon/look_find.ico")
    default:
      return
  }
}
export const getBackground = () => {
  const url = window.location.hostname.split(".")
  const currentDomain = url.length === 3 ? url[1] : url[0]

  switch (currentDomain) {
    case "ищу-найду":
    default:
      return "look_find.jpg"
  }
}

export const getLogo = () => {
  const url = window.location.hostname.split(".")
  const currentDomain = url.length === 3 ? url[1] : url[0]

  switch (currentDomain) {
    case "ищу-найду":
      return {
        light: "look_find_light.svg",
        dark: "look_find_dark.svg",
      }
    case "meetor":
    default:
      return {
        light: "meetor.svg",
        dark: "meetor.svg",
      }
  }
}

export const setStyles = async (theme?: "2023" | string) => {
  switch (theme) {
    case "2023":
      localStorage.setItem("data-app-theme", "theme_1")
      document.documentElement.setAttribute("data-app-theme", "theme_1")
      break
    default:
      localStorage.setItem("data-app-theme", "")
      document.documentElement.removeAttribute("data-app-theme")
  }
}